body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-margin-right {
  margin-right: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

.table-wrapper{
  max-height: 600px;
  overflow-y: scroll;

}

.is-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #9ea0b6;
}

.is-scroll::-webkit-scrollbar
{
	width: 10px;
	background-color: #4d5494;
}

.is-scroll::-webkit-scrollbar-thumb
{
	background-color: #4d5494;
	border: 2px solid #969eb3;
  border-radius: 10px;
}


/* .ol-control {
  position: absolute;
  background-color: rgba(218, 32, 32, 0.4) !important;
  color: rgba(218, 32, 32, 0.4) !important;
  border-radius: 4px;
  padding: 2px;
} */
.ol-side-panel-content {
  width: 250px;
  color: #fff;
  background-color: rgb(0 0 0 / 60%) !important;
}
.ol-control button {
  height: 2em !important;
  width: 2em !important;
  background-color: rgb(0 0 0 / 60%) !important;
}
.ol-control.ol-legend {
  background-color: white !important;
  left: unset !important;
  right: 0em !important;
}
.data-source {
  background-color: rgb(255 255 255 / 60%) !important;
  color: darkblue;
  font-weight: bolder;
  padding: 20em;
  top: unset !important;
  bottom: 0.5em;
  left: 0em;
}
.layer-switcher {
  right: 0em !important;
  top: 12em !important;
}
.my-fullscreen {
  top: 0em;
  right: 0em;
}
.ol-control.ol-print {
  top: 8em !important;
  right: 0em;
  left: unset !important;
}
.my-zoom {
  top: 3em;
  right: 0em;
}
.ol-ext-buttons {
  display: none;
}
.custom-control {
  top: 8em;
  right: 0em;
}
.ol-touch .custom-control {
  top: 80px;
}

.ol-side-panel {
  background-color: transparent !important;
}
.ol-scale-bar {
  position: absolute !important;
  bottom: 10px !important;
  left: -70% !important;
  padding: 0 15px !important;
}

.ol-scale-line {
  bottom: 2.5em !important;
  position: absolute !important;
  left: 30% !important;
}

.ol-control button {
  align-items: center;
  justify-content: center;
}

.popup-btn {
  margin-top: 8px;
  height: 30px;
  color: #757575;
  background: #fff;
  font-size: 16px;
}

.popup-btn:hover {
  color: #fd6b21;
  background: #fff0e8;
}

/* #legende {
  position: absolute;
  bottom: 0;
  right: 2em;
  background-color: #fff;
  z-index: 100000;
}

#legende img {
  width: 20px;
  height: auto;
} */

.ol-side-panel .ol-side-panel-tabs > button {
  margin: 2px !important;
}

.my-scale {
  bottom: 2.5em;
  position: absolute;
  left: 30%;
  width: fit-content;
}
